import styled, { css } from 'styled-components';
import { Card as CardMUI } from '@mui/material';
import pallete from 'TGComponents/theme/pallete';

export const Card = styled(CardMUI)`
  box-shadow: none;
  border-radius: 16px;
  background-color: #f7f9fa;
  height: 100%;
  min-height: 150px;
  width: 100%;
  min-width: 220px;
  padding: 20px;
`;

export const Title = styled.h4`
  color: rgba(34, 34, 34, 0.56);
  margin-bottom: 9px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Total = styled.h5`
  color: #222222;
  font-size: var(--type-size-lg, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5rem;
`;

export const Footer = styled.div`
  background-color: #fafafa;

  button {
    font-size: 0.875rem;
    background: none;
    border: 0;
    font-weight: 500;
    color: ${pallete.base.blackAlpha};
    padding: 0;

    &:hover {
      color: #00b2ff;
    }
  }
`;

const wrapperModifiers = {
  up: () => css`
    border-bottom: 7px solid rgba(83, 164, 98, 1);
  `,
  down: () => css`
    border-top: 7px solid rgba(215, 43, 43, 1);
  `,
  unchanged: () => css`
    width: 10px;
    height: 10px;
    background-color: ${pallete.base.blackAlpha};
  `,
};

export const IconArrow = styled.div`
  ${({ type }) => css`
    display: flex;
    align-items: center;
    color: ${pallete.base.blackAlpha};
    font-size: 0.75rem;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      margin-right: 0.5rem;
      ${wrapperModifiers[type || 'unchanged']()}
    }
  `}
`;
