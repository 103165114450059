// Função utilitária para formatar um valor de data de nascimento para um  formato brasileiro (dia/mês/ano)
const formatNascimento = (nascimento) => {
  let formattedNascimento = '';

  if (nascimento) {
    let dateParts;

    // Check if the input is in YYYY-MM-DD format
    if (/^\d{4}-\d{2}-\d{2}$/.test(nascimento)) {
      dateParts = nascimento.split('-');
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];
      formattedNascimento = `${day}/${month}/${year}`;
    }
    // Check if the input is in DD/MM/YYYY format
    else if (/^\d{2}\/\d{2}\/\d{4}$/.test(nascimento)) {
      dateParts = nascimento.split('/');
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
      formattedNascimento = `${day}/${month}/${year}`;
    }
    // Invalid format case: return the original string
    else {
      return nascimento;
    }
  }

  return formattedNascimento;
};

//Funcao para retirar as / e formatar num padrao YYYY/MM/DD para poder enviar para o backend
const desformatNascimento = (nascimento) => {
  const [dia, mes, ano] = nascimento.split('/');
  return `${ano}-${mes}-${dia}`;
};

const removeNonNumericCharacters = (value) => {
  return value.replace(/[^\d]+/g, '');
};

const cleanImageUrl = (url) => {
  if (!url) return null;
  return url.replace(/\\|"|'/g, '');
};

const formatToBrl = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

function parseBrazilianNumber(value) {
  const cleanedValue = value ? value.replace('.', '').replace(',', '.') : 0;

  return parseFloat(cleanedValue);
}

function formatToBrazilianCurrency(value) {
  if (typeof value !== 'string' || value.trim() === '') {
    return 'R$ 0,00';
  }

  // Remove espaços em branco desnecessários
  value = value.trim();

  // Regex para validar o formato da string (ex: '11.111.222,33')
  const regex = /^\d{1,3}(\.\d{3})*,\d{2}$/;

  if (!regex.test(value)) {
    throw new Error("Formato de string inválido. Deve ser '11.111.222,33'.");
  }

  // Remove os pontos e substitui a vírgula por ponto para conversão
  const cleanedValue = value.split('.').join('').replace(',', '.');

  // Converte a string para número
  const number = parseFloat(cleanedValue);

  if (isNaN(number)) {
    throw new Error('Não foi possível converter a string para número.');
  }

  // Formata o número para o formato brasileiro (ex: 'R$ 1.234,56')
  const formattedNumber = number
    .toFixed(2) // Garante duas casas decimais
    .replace('.', ',') // Substitui ponto decimal por vírgula
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Adiciona separadores de milhar

  return `R$ ${formattedNumber}`;
}

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export {
  formatNascimento,
  desformatNascimento,
  removeNonNumericCharacters,
  cleanImageUrl,
  formatToBrl,
  capitalizeFirstLetter,
  parseBrazilianNumber,
  formatToBrazilianCurrency,
};
