// LayoutDefault.jsx
import React from 'react';
import { Box, Stack, Card, Grid, Typography } from '@mui/material';
import { Fade } from '@mui/material';
import { TGBack } from 'TGComponents/global';

const LayoutDefault = ({
  pageTitle,
  page,
  paddingTGLayout,
  borderRadiusTGLayout,
  pagetitleRight,
  ...props
}) => {
  return (
    <Box
      sx={{ overflowX: 'hidden', minHeight: '90vh' }}
      paddingInline={{ xs: '0px', md: '20px' }}
      {...props}
    >
      <Grid container spacing={'20px'}>
        <Fade in={true} timeout={1100} unmountOnExit>
          <Grid item xs={12} md={12} xl={12}>
            <Box
              bgcolor='#ffffff'
              padding={paddingTGLayout || ['20px', '20px', '40px']}
              borderRadius={borderRadiusTGLayout || '16px'}
            >
              {props.backHref && (
                <Box mb='20px'>
                  <TGBack href={props.backHref} />
                </Box>
              )}
              {pageTitle && (
                <Stack justifyContent='space-between' direction='row'>
                  <Typography
                    variant='h5'
                    fontWeight={600}
                    marginBottom={'12px'}
                    color='initial'
                  >
                    {pageTitle}
                  </Typography>
                  {pagetitleRight}
                </Stack>
              )}

              {page}
            </Box>
          </Grid>
        </Fade>
      </Grid>
    </Box>
  );
};

export default LayoutDefault;
