import React from 'react';

import { Box, CardContent, Skeleton } from '@mui/material';
import { Card } from './styles';

const CardDetailsSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ mb: '16px' }}
          gap={['4px', '4px', '24px']}
        >
          <Skeleton variant='rounded' width='100%' height={20} />

          <Skeleton variant='circular' width={20} height={20} />
        </Box>

        <Skeleton
          variant='rounded'
          width='100%'
          height={20}
          sx={{ maxWidth: '180px', mb: '16px' }}
        />
        <Skeleton
          variant='rounded'
          width='100%'
          height={15}
          sx={{ maxWidth: '160px' }}
        />
      </CardContent>
    </Card>
  );
};

export default CardDetailsSkeleton;
