// MenuItemsEvent.js
import { credenciamento } from 'assets/svgs';
import { pixel } from 'assets/svgs';
import {
  cooproducao,
  resumo,
  formulario,
  paginaVenda,
  ofertas,
  cupom,
  usersGroup,
  cupomPercentage,
  convite,
} from 'assets/svgs';

const MenuItemsEvent = [
  {
    text: 'Resumo',
    icon: resumo,
    to: 'eventos/resumo',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  {
    text: 'Página de vendas',
    icon: paginaVenda,
    to: 'eventos/editar',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  {
    text: 'Ingressos',
    icon: cupom,
    to: 'eventos/ingressos',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  {
    text: 'Pagamento',
    icon: ofertas,
    to: 'eventos/pagamento',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  {
    text: 'Cupom',
    icon: cupomPercentage,
    to: 'eventos/cupons',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  {
    text: 'Formulário',
    icon: formulario,
    to: 'eventos/formulario',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  //{
  //  text: 'Convites',
  //  icon: convite,
  //  to: 'eventos/convites',
  //  roles: [
  //    'PRODUTOR-OPERACIONAL',
  //    'PRODUTOR-ADMIN',
  //    'PROPRIETARIO-SOCIO',
  //    'PRODUTOR-FINANCEIRO',
  //  ],
  //  adm: true,
  //},
  {
    text: 'Participantes',
    icon: usersGroup,
    to: 'eventos/participantes',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  {
    text: 'Coprodução',
    icon: cooproducao,
    to: 'eventos/coproducao',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  {
    text: 'Credenciamento',
    icon: credenciamento,
    to: 'eventos/credenciamento',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
  {
    text: 'Pixel',
    icon: pixel,
    to: 'eventos/rastreamento',
    roles: [
      'PRODUTOR-OPERACIONAL',
      'PRODUTOR-ADMIN',
      'PROPRIETARIO-SOCIO',
      'PRODUTOR-FINANCEIRO',
    ],
    adm: true,
  },
];

export default MenuItemsEvent;
