import React from 'react';

import { CardContent, Skeleton, Stack } from '@mui/material';
import { Card } from './styles';

const CardStatusSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Skeleton
          variant='rounded'
          width='100%'
          height={20}
          sx={{ mb: '16px' }}
        />
        <Skeleton variant='rounded' width={115} height={25} />
        <Skeleton
          variant='rounded'
          width='100%'
          height={25}
          sx={{ my: '20px' }}
        />
        <Skeleton
          variant='rounded'
          width='100%'
          height={'40px'}
          sx={{ mb: '16px' }}
        />
        <Stack direction='row' gap='4px'>
          <Skeleton variant='rounded' height={'25px'} width='100%' />
          <Skeleton variant='circular' height={'25px'} width='25px' />
          <Skeleton variant='circular' height={'25px'} width='25px' />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardStatusSkeleton;
