import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TGBadge from 'TGComponents/global/TGBadge';
import menuIcon from 'assets/icons/menuIcon.svg';
import { TGTooltip } from 'TGComponents/layout/TGTobBar/stylesMobile';
import { IconButton, useMediaQuery } from '@mui/material';
import TGDropdown from 'TGComponents/global/TGDropdown';
import exit from 'assets/icons/exit.svg';
import logoInicial from 'assets/logo-inicial.svg';
import { belt } from 'assets/svgs';
import menuItems from 'data/navs/MenuItems';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TGSideBarMobile from './TGSideBarMobile';
import { store } from 'store';
import { shortenText } from 'lib/utils';
import TGSidebarRight from 'TGComponents/global/TGSideBarRight';
import { Menu, MenuItem } from '@mui/material';
import TGCopyToClipboard from 'TGComponents/global/TGCopyToClipboard';

const TGTopBar = () => {
  const [isOpen, setisOpen] = useState(false);
  const [user, setUser] = useState(store.getState().user);
  const isMobile = useMediaQuery('(max-width:1080px)');
  const [openDropdownMobile, setOpenDropdownMobile] = useState(false);

  // Garante que o user seja carregado antes da página montar
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const newUser = store.getState().user;
      setUser(newUser);
    });

    return () => unsubscribe();
  }, []);

  const DropdownOptions = [
    { label: 'Minha conta', to: 'perfil?tab=0' },
    { label: 'Meu negócio', to: 'perfil?tab=1' },
    { label: 'Sair', to: '/logout' },
  ];

  return (
    <>
      <AppBar
        className='topBar'
        sx={{
          backgroundColor: 'white',
          position: 'fixed',
          top: 0,
          zIndex: 199,
          height: { xs: '72px', lg: '96px' },
          borderRadius: '0px',
          padding: '0px 15px',
        }}
        elevation='0'
      >
        <Toolbar
          id='testexddd'
          className='topBar-content'
          sx={{
            alignItems: 'center',
            height: { xs: '72px', lg: '98px' },
          }}
          width='100%'
        >
          {/* Drawer Button */}
          <Typography
            component={Link}
            to='/'
            sx={{ display: { xs: 'none', lg: 'block' } }}
          >
            <img src={logoInicial} className='logo-default' alt='logo' />
          </Typography>

          <IconButton
            size='medium'
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={() => setisOpen(true)}
            sx={{ display: { xs: 'block', lg: 'none', ml: 3 } }}
          >
            <img src={menuIcon} alt='dragimg' height={'18px'} width={'18px'} />
          </IconButton>

          <Box display='flex' alignItems='center' gap={2}>
            {/*<Box display={['none', 'none', 'flex']}>
              <TGBadge src={belt} showStatusPart={true} />
            </Box>*/}

            <Box display={'flex'}>
              <TGDropdown
                customIcon={
                  <Avatar
                    sx={{ border: '1px solid #EEE' }}
                    alt={user.nome}
                    src={user.foto}
                  />
                }
                options={DropdownOptions}
              />
            </Box>

            <Box display={['none', 'none', 'block']} alignItems={'center'}>
              <Typography
                variant=''
                noWrap
                color='#222'
                fontSize='0.90rem'
                fontWeight={600}
                component='div'
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
              >
                {user.nome}
              </Typography>
              <TGCopyToClipboard target={user?.email} message='Email copiado!'>
                <TGTooltip title={user.email}>
                  <Typography
                    variant=''
                    noWrap
                    color='neutral.secondary'
                    fontSize='0.75rem'
                    fontWeight={600}
                    component='div'
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                  >
                    {shortenText(user.email, user.nome.length)}
                  </Typography>
                </TGTooltip>
              </TGCopyToClipboard>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <TGSidebarRight
        isOpen={openDropdownMobile}
        onClose={() => setOpenDropdownMobile(false)}
      >
        <Menu
          id='tg-dropdown-profile-menu'
          slotsPaperProps={{
            style: {
              maxHeight: DropdownOptions.length * 48,
              width: '20ch',
            },
          }}
        >
          {DropdownOptions.map((option) => (
            <MenuItem
              key={option.label}
              // onClick={(event) => handleMenuItemClick(option, event)}
              style={{
                minHeight: '40px',
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </TGSidebarRight>

      {isOpen && (
        <>
          <TGSideBarMobile menuItems={menuItems(user)} setisOpen2={setisOpen} />
        </>
      )}
    </>
  );
};

export default TGTopBar;
