export const LessCircle = ({ size = 24, color }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_124_6087)'>
        <path
          d='M9.93296 18.3332C14.5163 18.3332 18.2663 14.5832 18.2663 9.99984C18.2663 5.4165 14.5163 1.6665 9.93296 1.6665C5.34961 1.6665 1.59961 5.4165 1.59961 9.99984C1.59961 14.5832 5.34961 18.3332 9.93296 18.3332Z'
          stroke='#222222'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M6.59961 10H13.2663'
          stroke='#222222'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_124_6087'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
