import React from 'react';
import { Box, CardContent } from '@mui/material';

import { Card, Footer, Title } from './styles';

export const CardWrapper = ({ title, children, footerText, onFooterClick }) => {
  return (
    <>
      <Card>
        <Box p='20px' position='relative'>
          <Title>{title}</Title>

          {children}
        </Box>

        {!!footerText && (
          <Footer>
            <button type='button' onClick={onFooterClick}>
              {footerText}
            </button>
          </Footer>
        )}
      </Card>
    </>
  );
};
