export const Drag = ({ size = 24, color }) => {
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.63385 5.66748C1.34095 5.96038 0.866081 5.96038 0.573187 5.66749C0.280293 5.37459 0.280292 4.89972 0.573184 4.60683L4.60336 0.576635C5.3722 -0.192213 6.62591 -0.192211 7.39476 0.576637L11.4249 4.60683C11.7178 4.89972 11.7178 5.37459 11.4249 5.66749C11.132 5.96038 10.6571 5.96038 10.3643 5.66748L6.3341 1.63729C6.15104 1.45423 5.84708 1.45423 5.66402 1.63729L1.63385 5.66748Z'
        fill='#222222'
        fill-opacity='0.56'
      />
      <path
        d='M1.63385 10.2196C1.34095 9.92672 0.866081 9.92672 0.573187 10.2196C0.280293 10.5125 0.280292 10.9874 0.573184 11.2803L4.60336 15.3105C5.3722 16.0793 6.62591 16.0793 7.39476 15.3105L11.4249 11.2803C11.7178 10.9874 11.7178 10.5125 11.4249 10.2196C11.132 9.92672 10.6571 9.92673 10.3643 10.2196L6.3341 14.2498C6.15104 14.4329 5.84708 14.4329 5.66402 14.2498L1.63385 10.2196Z'
        fill='#222222'
        fill-opacity='0.56'
      />
    </svg>
  );
};
