export const ArrowTopRight = ({ width = 24, height = 24, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9068 12.9974C14.9068 13.4116 15.2426 13.7474 15.6568 13.7474C16.0711 13.7474 16.4068 13.4116 16.4068 12.9974L16.4068 4.73111C16.5094 4.46318 16.4526 4.14828 16.2367 3.93231C16.2283 3.92398 16.2199 3.91589 16.2112 3.90803C16.2034 3.89946 16.1954 3.89105 16.1872 3.88282C16.0465 3.74216 15.8558 3.66315 15.6568 3.66315L7.07257 3.66315C6.65836 3.66315 6.32257 3.99893 6.32257 4.41315C6.32257 4.82736 6.65836 5.16315 7.07257 5.16315L13.9452 5.16315L3.86231 15.246C3.56941 15.5389 3.56941 16.0138 3.86231 16.3067C4.1552 16.5996 4.63007 16.5996 4.92297 16.3067L14.9068 6.3228L14.9068 12.9974Z"
        fill={color}
        fill-opacity="0.56"
      />
    </svg>
  );
};
