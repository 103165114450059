export const Back = ({ width = 20, height = 20, color = '#222222' }) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='path-1-inside-1_146_4914' fill='white'>
        <path d='M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z' />
      </mask>
      <path
        d='M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z'
        fill='#F7F9FA'
      />
      <path
        d='M16 31C7.71573 31 1 24.2843 1 16H-1C-1 25.3888 6.61116 33 16 33V31ZM31 16C31 24.2843 24.2843 31 16 31V33C25.3888 33 33 25.3888 33 16H31ZM16 1C24.2843 1 31 7.71573 31 16H33C33 6.61116 25.3888 -1 16 -1V1ZM16 -1C6.61116 -1 -1 6.61116 -1 16H1C1 7.71573 7.71573 1 16 1V-1Z'
        fill='#222222'
        fill-opacity='0.08'
        mask='url(#path-1-inside-1_146_4914)'
      />
      <g clip-path='url(#clip0_146_4914)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M19.2697 22.5108C19.6212 22.1593 19.6212 21.5895 19.2697 21.238L14.4335 16.4018C14.2138 16.1821 14.2138 15.8174 14.4335 15.5977L19.2697 10.7615C19.6212 10.41 19.6212 9.84018 19.2697 9.4887C18.9183 9.13723 18.3484 9.13723 17.9969 9.4887L13.1607 14.3249C12.2381 15.2475 12.2381 16.752 13.1607 17.6746L17.9969 22.5108C18.3484 22.8623 18.9183 22.8623 19.2697 22.5108Z'
          fill='#222222'
          fill-opacity='0.56'
        />
      </g>
      <defs>
        <clipPath id='clip0_146_4914'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(4 4)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
