export const CopyIcon2 = ({ width = 24, height = 24, color = '#FAFAFA' }) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.5903 6.49998C14.5903 6.00292 14.9933 5.59998 15.4903 5.59998H17.0003C20.5285 5.59998 23.4003 8.48398 23.4003 12C23.4003 15.5281 20.5163 18.4 17.0003 18.4H15.4903C14.9933 18.4 14.5903 17.997 14.5903 17.5C14.5903 17.0029 14.9933 16.6 15.4903 16.6H17.0003C19.5243 16.6 21.6003 14.5319 21.6003 12C21.6003 9.47597 19.5322 7.39998 17.0003 7.39998H15.4903C14.9933 7.39998 14.5903 6.99703 14.5903 6.49998Z'
        fill='#FAFAFA'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.6001 12C1.6001 8.47292 4.47304 5.59998 8.0001 5.59998H9.5001C9.99716 5.59998 10.4001 6.00292 10.4001 6.49998C10.4001 6.99703 9.99716 7.39998 9.5001 7.39998H8.0001C5.46715 7.39998 3.4001 9.46703 3.4001 12C3.4001 14.524 5.46822 16.6 8.0001 16.6H9.5001C9.99716 16.6 10.4001 17.0029 10.4001 17.5C10.4001 17.997 9.99716 18.4 9.5001 18.4H8.0001C4.47198 18.4 1.6001 15.516 1.6001 12Z'
        fill='#FAFAFA'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.6001 12C7.6001 11.5029 8.00304 11.1 8.5001 11.1H16.5001C16.9972 11.1 17.4001 11.5029 17.4001 12C17.4001 12.497 16.9972 12.9 16.5001 12.9H8.5001C8.00304 12.9 7.6001 12.497 7.6001 12Z'
        fill='#FAFAFA'
      />
    </svg>
  );
};
