import React from 'react';

import { CardContent, Skeleton } from '@mui/material';
import { Card } from './styles';
import { ItemIconListSkeleton } from '../../components';

const CardDetailsSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Skeleton
          variant='rounded'
          width='100%'
          height={20}
          sx={{ mb: '16px' }}
        />

        {[1, 2, 3].map((item) => (
          <ItemIconListSkeleton key={item} />
        ))}
      </CardContent>
    </Card>
  );
};

export default CardDetailsSkeleton;
