import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { pickersLayoutClasses } from '@mui/x-date-pickers';
import { calendarIcon } from 'assets/svgs';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import { InputText } from 'TGComponents/global/Inputs';

const CalendarPicker = ({
  startDateValue,
  startTimeValue,
  endDateValue,
  endTimeValue,
  setStartDateValue,
  setStartTimeValue,
  setEndDateValue,
  setEndTimeValue,
  dateFormat = 'YYYY-MM-DD',
  hideTime,
  disablePast = true,
  disableFuture,
  onSelectEndDate = false,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState('');
  const [errors, setErrors] = useState({ startTime: '', endTime: '' });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    setStartDate(startDate);
    setStartTime(startTimeValue);
    setEndDate(endDate);
    setEndTime(endTimeValue);
  }, [startDateValue, startTimeValue, endDateValue, endTimeValue]);

  const handleResetCalendar = () => {
    const now = dayjs().toDate();
    setStartDate('');
    setStartTime('');
    setEndDate('');
    setEndTime('');
    setStartDateValue('');
    setStartTimeValue('');
    setEndDateValue('');
    setEndTimeValue('');
  };

  dayjs.locale('pt-br');
  useEffect(() => {
    const weekdays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

    // FIX WEEK LABEL
    document
      .querySelectorAll(
        '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel'
      )
      .forEach((element, index) => {
        element.innerText = weekdays[index];
      });
  }, []);

  const validateTime = (field, value) => {
    const timeRegex = /^([0-1]?\d|2[0-4]):([0-5]\d)$/;
    if (!timeRegex.test(value)) {
      setErrors({ ...errors, [field]: 'Horário inválido' });
    } else {
      const [hours, minutes] = value.split(':');
      if (
        (parseInt(hours, 10) > 24 ||
          (parseInt(hours, 10) === 24 && parseInt(minutes, 10) !== 0)) &&
        value !== '24:00'
      ) {
        setErrors({ ...errors, [field]: 'Hora não pode ser maior que 24' });
      } else {
        setErrors({ ...errors, [field]: '' });
      }
    }
  };
  const handleDateChange = (field, value) => {
    console.log('🚀 ~ handleDateChange ~ value:', value);
    console.log('🚀 ~ handleDateChange ~ field:', field);
    const date = dayjs(value, 'DD/MM/YYYY');
    if (date.isValid()) {
      if (field === 'startDate') {
        setStartDate(date.toDate());
        setStartDateValue(date.format(dateFormat));
      } else if (field === 'endDate') {
        setEndDate(date);
        setEndDateValue(date.format(dateFormat));
      }
    } else {
      // Handle invalid date input
      if (field === 'startDate') {
        setStartDate(null); // Reset to null or ''
        setStartDateValue('');
      } else if (field === 'endDate') {
        setEndDate(null); // Reset to null or ''
        setEndDateValue('');
      }
    }
  };

  // Função para impedir a tecla "Backspace"
  const preventBackspace = (e) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
    }
  };

  const handleTimeChange = (field, value) => {
    setErrors({ ...errors, [field]: '' });
    const numericRegex = /^[0-9:]*$/;
    if (value.length === 5 && numericRegex.test(value)) {
      validateTime(field, value);
    }

    if (field === 'startTime') {
      setStartTime(value);
      setStartTimeValue(value);
    } else if (field === 'endTime') {
      setEndTime(value);
      setEndTimeValue(value);
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format(dateFormat);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
        <Stack
          sx={{
            borderRadius: isMobile ? '0' : '12px',
            border: isMobile ? 'none' : '1px solid rgba(34, 34, 34, 0.08)',
            background: '#FFF',
            justifyContent: isMobile ? 'flex-start' : 'center',
            paddingTop: '12px',
            alignItems: isMobile ? 'flex-start' : 'center',
            maxWidth: isMobile ? '100%' : '350px',
            boxShadow: '0px 4px 8px 0px #0000001F',
          }}
        >
          <Stack
            gap='16px'
            padding='0 16px'
            position='relative'
            alignItems='center'
            width={isMobile ? '100%' : 'auto'}
          >
            <Stack
              gap='12px'
              direction='flex'
              width={hideTime ? '100%' : 'auto'}
            >
              {/* COLUNA 01 */}
              <Stack
                gap='4px'
                sx={{
                  width: hideTime ? '100%' : 'auto',
                  padding: hideTime ? '0 16px' : '0',
                }}
              >
                <Typography variant='label-s' color='#2222228E'>
                  Começa em*
                </Typography>
                <Box width={'100%'} display={'flex'} alignItems='center'>
                  <Box
                    width='46px'
                    height='45px'
                    borderRadius={'8px 0 0px 8px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    marginRight='-48px'
                    zIndex='9'
                  >
                    <img src={calendarIcon} alt='icon' />
                  </Box>

                  <CustomInput
                    value={
                      startDate ? dayjs(startDate).format('DD/MM/YYYY') : ''
                    }
                    placeholder='Data inicial'
                    onChange={(e) =>
                      handleDateChange('startDate', e.target.value)
                    }
                    onKeyDown={preventBackspace}
                  />
                </Box>
              </Stack>
              {/* COLUNa 02 */}
              {!hideTime && (
                <Stack gap='4px'>
                  <Typography variant='label-s' color='#2222228E'>
                    Horario de inicio:
                  </Typography>
                  <Box
                    maxWidth={'108px'}
                    position='relative'
                    alignItems='center'
                  >
                    <InputText
                      type='text'
                      value={startTime}
                      name='startTime'
                      mask='99:99'
                      placeholder='00:00'
                      onChange={(e) =>
                        handleTimeChange('startTime', e.target.value)
                      }
                    />
                    {errors.startTime && (
                      <Typography
                        color='red.600'
                        variant='body-s'
                        sx={{ position: 'absolute', bottom: '-20px' }}
                      >
                        {errors.startTime}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              )}
            </Stack>
            <Stack
              gap='12px'
              direction='flex'
              width={hideTime ? '100%' : 'auto'}
            >
              {/* Data de inicio */}
              <Stack
                gap='4px'
                sx={{
                  width: hideTime ? '100%' : 'auto',
                  padding: hideTime ? '0 16px' : '0',
                }}
              >
                <Typography variant='label-s' color='#2222228E'>
                  Termina em*
                </Typography>
                <Box width={'100%'} display={'flex'} alignItems='center'>
                  <Box
                    width='46px'
                    height='45px'
                    borderRadius={'8px 0 0px 8px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    marginRight='-48px'
                    zIndex='1'
                  >
                    <img src={calendarIcon} alt='icon' />
                  </Box>
                  <CustomInput
                    value={
                      dayjs(endDate).isValid()
                        ? dayjs(endDate).format('DD/MM/YYYY')
                        : ''
                    }
                    placeholder='Data final'
                    onChange={(e) =>
                      handleDateChange('endDate', e.target.value)
                    }
                    onKeyDown={preventBackspace}
                  />
                </Box>
              </Stack>
              {/* HORA FINAL */}

              {!hideTime && (
                <Stack gap='4px'>
                  <Typography variant='label-s' color='#2222228E'>
                    Hora do final*
                  </Typography>
                  <Box maxWidth={'108px'}>
                    <InputText
                      type='text'
                      value={endTime}
                      name='endTime'
                      mask='99:99'
                      placeholder='00:00'
                      onChange={(e) =>
                        handleTimeChange('endTime', e.target.value)
                      }
                    />
                    {errors.endTime && (
                      <Typography color='red.600' variant='body-s'>
                        {errors.endTime}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              )}
            </Stack>
            <Box width={['auto', '348px']}>
              <StaticDateRangePicker
                displayStaticWrapperAs='desktop'
                disablePast={disablePast}
                disableFuture={disableFuture}
                calendars={1} // Mostra apenas um calendário
                value={[dayjs(startDate), dayjs(endDate)]}
                onChange={(newValue) => {
                  setStartDate(dayjs(newValue[0], 'DD/MM/YYYY').toDate());
                  setEndDate(dayjs(newValue[1], 'DD/MM/YYYY').toDate());
                  setStartDateValue(formatDate(newValue[0]));
                  setEndDateValue(formatDate(newValue[1]));
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <CustomInput
                      {...startProps}
                      value={formatDate(startProps.value)}
                      onChange={(e) => {
                        const date = e.target.value;
                        console.log('🚀 ~ date:', date);
                        setStartDate(dayjs(date, 'DD/MM/YYYY').toDate());
                        startProps.onChange(e);
                      }}
                    />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <CustomInput
                      {...endProps}
                      value={formatDate(endProps.value)}
                      onChange={(e) => {
                        const date = e.target.value;
                        console.log('🚀 ~ date:', date);
                        setEndDate(dayjs(date, 'DD/MM/YYYY').toDate());
                        endProps.onChange(e);
                      }}
                    />
                  </React.Fragment>
                )}
                sx={{
                  [`.${pickersLayoutClasses.contentWrapper}`]: {
                    alignItems: 'center',
                  },
                }}
              />
              {/* BOTAO DE LIMPAR */}
              <Box
                onClick={handleResetCalendar}
                bgcolor='rgba(34, 34, 34, 0.08)'
                sx={{
                  borderTop: isMobile ? 'none' : '1px solid #22222214',
                  background: '#FAFAFA',
                  textAlign: 'center',
                  padding: '16px',
                  marginTop: isMobile ? '140px' : '',
                  borderRadius: isMobile ? '8px' : '',
                  border: isMobile ? '2px solid  rgba(34, 34, 34, 0.08)' : '',
                  borderEndEndRadius: '8px',
                  borderEndStartRadius: '8px',
                  cursor: 'pointer',
                }}
              >
                <Typography variant='body-l'>Limpar</Typography>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </LocalizationProvider>
      {/* CSS */}
      <style jsx>{`
        div[style*='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;'] {
          display: none !important;
        }

        /* css abaixo foi adicionado para remover o bug React: ResizeObserver loop completed with undelivered notifications */
        iframe#webpack-dev-server-client-overlay {
          display: none !important;
        }
        /* css acima foi adicionado para remover o bug React: ResizeObserver loop completed with undelivered notifications */

        .MuiPickersDay-dayOutsideMonth {
          color: #999999 !important;
        }
        .MuiPickersDay-root.Mui-selected {
          color: white !important;
        }

        .MuiPickersDay-today {
          border: 1px solid #00b2ff !important;
          background-color: transprent !important;
        }

        .MuiDateRangePickerDay-day,
        .MuiTypography-root-MuiDayCalendar-weekDayLabel {
          font-size: 14px !important;
          height: 40px;
          width: 40px;
          margin: 2px;
          font-weight: 400 !important;
        }

        .MuiPickersCalendarHeader-root {
          position: relative !important;
          color: #999 !important;
          font-weight: 500 !important;
          display: grid;
          align-items: center;
          margin-top: 16px !important;
          width: 100%;
          max-width: 340px;
        }

        .MuiPickersLayout-contentWrapper .MuiIconButton-edgeEnd {
          position: absolute;
          left: 0px;
          top: -5px;
        }

        .MuiPickersLayout-contentWrapper .MuiIconButton-edgeStart {
          position: absolute;
          right: 0px;
          top: -5px;
        }

        .MuiPickersCalendarHeader-labelContainer {
          margin-left: auto !important;
        }
        .MuiPickersCalendarHeader-label {
          margin-left: 0px !important;
          font-size: 14px !important;
        }
        .MuiPickersPopper-paper {
          box-shadow: none !important;
          border: 1px solid red !important;
          border-radius: 12px !important;
          margin-top: 4px;
        }

        .MuiDateRangePickerDay-root:first-of-type {
          border-top-left-radius: 0% !important;
          border-bottom-left-radius: 0% !important;
        }

        .MuiDateRangePickerDay-root:last-of-type {
          border-top-right-radius: 0% !important;
          border-bottom-right-radius: 0% !important;
        }

        /* Remover pontilhado */

        .MuiDateRangePickerDay-rangeIntervalDayPreview {
          border-color: transparent !important;
          background-color: #fafafa;
        }

        .MuiDayCalendar-slideTransition {
          min-height: 310px !important;
        }

        .css-i5q14k-MuiDayCalendar-header {
          justify-content: space-around;
          font-size: 14px !important;
        }

        .MuiDayCalendar-weekDayLabel {
          font-size: 14px !important;
        }
        .MuiDayCalendar-header {
          justify-content: space-around !important;
          font-size: 14px !important;
        }
      `}</style>
    </>
  );
};
// STYLED
const CustomInput = styled(InputText)`
  width: 100%;
  padding: 13px 10px;
  max-height: 51px;
  text-indent: 40px;
  border: 1px solid #ccc;
  min-width: 92px;
  border-radius: 4px;
  font-size: 14px;
  border-radius: var(--space-radius-sm, 8px);
  border: var(--space-stroke-sm, 1px) solid
    var(--color-stroke-neutral-standard-secondary, rgba(34, 34, 34, 0.08));
  background: var(--color-background-neutral-primary, #fff);
`;
export default CalendarPicker;
