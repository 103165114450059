import { useRef, useState, useCallback, useContext } from 'react';
import Cropper from 'react-easy-crop';
import { getOrientation } from 'get-orientation/browser';
import { getCroppedImg, getRotatedImage } from 'utils/canvasUtils';
import TGButton from 'TGComponents/global/TGButton';
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  Skeleton,
} from '@mui/material';
import dragimg from 'assets/svgs/productadd/dragimg.svg';
import { Button } from 'TGComponents/global';
import { AlertsContext } from 'providers/alerts-context';

const Crop = ({
  height = '200px',
  width,
  handleCrop,
  label,
  textHelper,
  cropTo = { width: 0, height: 0 },
  defaultImage = null,
  loadingComponent,
  errorMessage,
  name,
}) => {
  const { addAlert } = useContext(AlertsContext);

  const flinput = useRef(null);
  const [imageSrc, setImageSrc] = useState(defaultImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
  const [isCropped, setIsCropped] = useState(defaultImage ? true : false);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  //const [croppedImage, setCroppedImage] = useState(null)
  const [loading, setLoading] = useState(false);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const loadFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width < cropTo?.width || height < cropTo?.height) {
          addAlert({
            message: `Erro: As dimensões da imagem não devem ser menores que as dimensões recomendadas.`,
            type: 'error',
            open: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          });
        } else {
          setOriginalSize({
            width: width,
            height: height,
          });
          setImageSrc(reader.result);
        }
      };

      img.src = '';
      img.src = event.target.result;
    };

    reader.readAsDataURL(file);

    e.target.value = '';
  };

  <input type='file' onChange={loadFile} />;

  const showCroppedImage = useCallback(async () => {
    setLoading(true);
    try {
      const width =
        cropTo.width > 0 && cropTo.width < originalSize.width
          ? cropTo.width
          : originalSize.width;
      const height = cropTo.height > 0 ? cropTo.height : width / 1.7776;

      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        { width, height }
      );

      setLoading(false);
      handleCrop(croppedImage);
      setIsCropped(true);
      setImageSrc(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation, cropTo, originalSize]);

  const changePic = useCallback(() => {
    //output(null)

    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setCroppedAreaPixels(null);
  }, []);

  return (
    <Stack gap='4px' name={name}>
      <Typography variant='label-s' color='rgba(34, 34, 34, 0.56)'>
        {label}
      </Typography>
      {loadingComponent ? (
        <Skeleton height='200px' />
      ) : (
        <Box
          display='flex'
          borderRadius='8px'
          justifyContent={'center'}
          alignItems={'center'}
          height={height}
          bgcolor='#F7F9FA'
          sx={{
            position: 'relative',
            backgroundColor: '#F7F9FA',
            width: '100%',
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <Stack gap='20px' alignItems={'center'}>
              <CircularProgress />
              <Typography variant='label-s' color='#00A7F0'>
                Processando imagem
              </Typography>
            </Stack>
          ) : (
            <>
              {imageSrc || defaultImage ? (
                isCropped || defaultImage ? (
                  <img
                    src={imageSrc || defaultImage}
                    alt='Imagem recortada'
                    style={{ objectFit: 'contain' }}
                    width={'100%'}
                    height={'100%'}
                  />
                ) : (
                  <>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      rotation={0}
                      zoom={zoom}
                      aspect={
                        cropTo.width > 0 && cropTo.height > 0
                          ? cropTo.width / cropTo.height
                          : 16 / 9
                      }
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </>
                )
              ) : (
                <Stack
                  textAlign={'center'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  width='100%'
                  height='100%'
                  onClick={() => flinput?.current?.click()}
                  sx={{ cursor: 'pointer' }}
                >
                  <img
                    src={dragimg}
                    alt='dragimg'
                    height={'32px'}
                    width={'32px'}
                  />
                  <Typography
                    color='#999999'
                    fontSize='12px'
                    fontWeight={'400'}
                    mt={'15px'}
                  >
                    Arraste 'uma imagem' aqui ou{' '}
                    <span style={{ color: '#00B2FF', cursor: 'pointer' }}>
                      clique para buscar
                    </span>
                  </Typography>
                </Stack>
              )}
              <input
                type='file'
                style={{ display: 'none' }}
                ref={flinput}
                accept='image/png, image/jpeg'
                onChange={loadFile}
              />
            </>
          )}
        </Box>
      )}
      {!imageSrc || isCropped || defaultImage ? null : (
        <ButtonGroup
          onRemoveClick={() => {
            setImageSrc(null);
            setIsCropped(false);
            handleCrop('');
          }}
          onSaveClick={showCroppedImage}
          isCropped={false}
        />
      )}
      {!isCropped && defaultImage && (
        <ButtonGroup
          onRemoveClick={() => {
            setImageSrc(null);
            setIsCropped(false);
            handleCrop('');
          }}
          onSaveClick={() => {
            setImageSrc(null);
            setIsCropped(false);
            handleCrop('');
          }}
          isCropped={true}
        />
      )}
      {isCropped && (
        <ButtonGroup
          onRemoveClick={() => {
            setImageSrc(null);
            setIsCropped(false);
            handleCrop('');
          }}
          onSaveClick={() => {
            setImageSrc(null);
            setIsCropped(false);
            handleCrop('');
          }}
          isCropped={true}
        />
      )}
      {!loadingComponent && errorMessage && (
        <Typography color='red.600' variant='body-s'>
          {errorMessage}
        </Typography>
      )}
      {textHelper && (
        <Typography variant='body-s' color='rgba(34, 34, 34, 0.56)'>
          {textHelper}
        </Typography>
      )}
    </Stack>
  );
};

export default Crop;

const ButtonGroup = ({ onRemoveClick, onSaveClick, isCropped }) => (
  <Stack
    width='100%'
    py='16px'
    direction='row'
    gap='20px'
    alignItems='center'
    justifyContent='center'
  >
    <Button
      size='small'
      variant='secondary'
      sx={{
        height: '30px',
        width: '75px',
        color: 'black',
        border: '1px solid rgba(34, 34, 34, 0.08)',
      }}
      onClick={onRemoveClick}
    >
      {isCropped ? 'Remover' : 'Alterar'}
    </Button>
    <Typography fontSize='12px' fontWeight='500' color='rgba(34, 34, 34, 0.56)'>
      Ou
    </Typography>
    <Button
      size='small'
      sx={{ height: '30px', width: '65px' }}
      onClick={isCropped ? onRemoveClick : onSaveClick}
    >
      {isCropped ? 'Alterar' : 'Salvar'}
    </Button>
  </Stack>
);
