import React from 'react';
import { CardContent, Typography } from '@mui/material';

import { Card, Footer, Title } from './styles';
import { IconArrow } from './styles';
import { Total } from './styles';
import { helpIcon } from 'assets/svgs';
import { TGTooltip } from 'TGComponents/layout/TGSideBar/styles';
import { CardInfoSkeleton } from 'TGComponents/global/skeletons';

export const CardInfo = ({
  title,
  total,
  footerText,
  onFooterClick,
  value = null,
  type,
  tooltipMessage,
  placement = 'top',
  loading,
}) => {
  return (
    <>
      {loading && <CardInfoSkeleton />}

      {!loading && (
        <Card>
          <Title variant='body-m'>
            {title}
            <TGTooltip title={tooltipMessage} placement={placement}>
              <img
                src={helpIcon}
                alt='icon-tooltip'
                style={{ cursor: 'pointer' }}
              />
            </TGTooltip>
          </Title>

          <Total>{total}</Total>

          {!!footerText && (
            <Footer>
              <Typography
                variant='body-m'
                color='rgba(34, 34, 34, 0.56)'
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    color: '#00A7F0',
                  },
                }}
                onClick={onFooterClick}
              >
                {footerText}
              </Typography>
            </Footer>
          )}

          {value !== null && <IconArrow type={type}>{value}</IconArrow>}
        </Card>
      )}
    </>
  );
};
