export const MenuBurguer = ({ width = 16, height = 16, color = '#222222' }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_57_1030)'>
        <path
          d='M1 4C1 3.44772 1.40294 3 1.9 3H18.1C18.5971 3 19 3.44772 19 4C19 4.55228 18.5971 5 18.1 5H1.9C1.40294 5 1 4.55228 1 4Z'
          fill='#222222'
        />
        <path
          d='M1 10C1 9.44771 1.40294 9 1.9 9H18.1C18.5971 9 19 9.44771 19 10C19 10.5523 18.5971 11 18.1 11H1.9C1.40294 11 1 10.5523 1 10Z'
          fill='#222222'
        />
        <path
          d='M1.9 15C1.40294 15 1 15.4477 1 16C1 16.5523 1.40294 17 1.9 17H18.1C18.5971 17 19 16.5523 19 16C19 15.4477 18.5971 15 18.1 15H1.9Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_57_1030'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
