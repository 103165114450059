import React, { useState } from 'react';
import { searchIcon } from 'assets/svgs';
import { Box, TextField, styled, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { SearchIcon } from 'TGComponents/icons/SearchIcon';

const InputWrapper = styled(Box)(({ theme, margin, width }) => ({
  width: width,
  borderRadius: 8,
  margin: margin || '20px 0 16px',
}));

const StyledTextField = styled(TextField)(({ textAlign }) => ({
  '& .MuiOutlinedInput-root': {
    'borderRadius': '480px',
    'width': '100%',
    'background': '#F7F9FA',
    'border': 0,
    'paddingRight': '12px',
    'paddingLeft': '16px',
    'textAlign': 'center',
    'height': '48px',
    'transition': 'all 0.3s',
    '&:hover': {
      'backgroundColor': '#F7F9FA',
      '& fieldset': {
        borderColor: 'rgba(34, 34, 34, 0.20)',
        fontSize: '12px',
      },
    },
  },

  '& .MuiInputBase-input': {
    borderRadius: '480px',
    width: '100%',
    background: '#F7F9FA',
    border: '0px solid transparent !important',
    paddingRight: '12px',
    fontSize: '14px',
    height: '48px',
    textAlign: textAlign,
  },
}));

const TGSearch = ({
  value,
  setValue,
  placeholder,
  onSearchChange,
  margin,
  width,
  disabled = false,
  displayIcon,
  onKeyDown, // opcional para controle do scanner
  inputId, // Opcional para fazer um querySelector ao usar scanner
  textAlign,
  clearIcon = true // parametro opcional setado como true
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleClear = () => {
    setValue('');
    if (onSearchChange) onSearchChange('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  return (
    <InputWrapper margin={margin} width={width}>
      <StyledTextField
        value={value}
        placeholder={placeholder}
        type='text'
        fullWidth
        id={inputId}
        onChange={(e) => onSearchChange(e.target.value)}
        onKeyDown={onKeyDown ? onKeyDown : handleKeyDown}
        disabled={disabled}
        onFocus={() => setIsFocused(true)}
        textAlign={textAlign}
        onBlur={() => setIsFocused(false)}
        InputProps={{
          startAdornment: (
            <Box mr='12px' display={displayIcon}>
              <SearchIcon size={24} color={isFocused ? '#00B2FF' : '#222222'} />
            </Box>
          ),
          endAdornment: value && clearIcon ? (
            <IconButton onClick={handleClear} size='large'>
              <ClearIcon
                style={{
                  fontSize: '20px',
                  color: isFocused ? '#2222228F' : 'inherit',
                }}
              />
            </IconButton>
          ) : null,
        }}
      />
    </InputWrapper>
  );
};

export default TGSearch;
